button.rec-dot{
    z-index: -10;
}

.rec.rec-arrow {
    border-radius: 0;
    background-color: white;
}

  .rec.rec.rec.rec-arrow:hover {
      background: white; /* #85DE77 #FF756D #BDD0EB*/
      color: black;
  }

  .rec.rec-arrow:disabled {
    visibility: hidden;
}

.rec.rec.rec-arrow:focus {
  background: white;
  color: black;
}