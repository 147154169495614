@import url('https://fonts.googleapis.com/css2?family=Sora&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,900;1,700&display=swap');

* {
  font-family: 'DM Sans', sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body.modal-open {
    position: fixed;
    overflow-y: hidden;
}

video::-webkit-media-controls {
    display:none !important;
}
